import { Component, Input, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';

@Component({
  selector: 'app-custom-select-form-control',
  templateUrl: './custom-select-form-control.component.html',
  styleUrls: ['./custom-select-form-control.component.scss'],
})
export class CustomSelectFormControlComponent implements ControlValueAccessor {
  @ViewChild('select') select: ControlValueAccessor;

  @Input() dataProvider: any[];
  @Input() valueField = 'id';
  @Input() labelField = 'label';
  @Input() labelFunction: (item: any) => string;
  @Input() placeholder = 'Select';
  @Input() hidePlaceholderOption = true;
  @Input() isMultiselect = false;

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  get control(): UntypedFormControl {
    return this.ngControl.control as UntypedFormControl;
  }

  writeValue(obj: any) {
    if (this.select) this.select.writeValue(obj);
  }

  registerOnChange(fn: any) {
    if (this.select) this.select.registerOnChange(fn);
  }

  registerOnTouched(fn: any) {
    if (this.select) this.select.registerOnTouched(fn);
  }

  setDisabledState?(isDisabled: boolean) {
    if (this.select && this.select.setDisabledState) this.select.setDisabledState!(isDisabled);
  }
}
