import { Injectable } from '@angular/core';
import {
  AccountSummary,
  ApiRestService,
  RedeemedReward,
  ReferenceData,
  SearchResult,
} from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
// import { RedeemedReward } from 'src/app/product-catalogue/model/redeemed-reward';
import { environment } from 'src/environments/environment';

import { Booking } from '../../model/booking';
import { BookingSearch } from '../../model/booking-search';
import { CommunicationsPreference } from '../../model/core/communications-preference';
import { Notification } from '../../model/core/notification';
import { Profile } from '../../model/core/profile';
import { RedemptionSearch } from '../../model/core/redemption-search';
import { SetPassword } from '../../model/core/set-password';
import { Transaction } from '../../model/core/transaction';
import { TransactionSearch } from '../../model/core/transaction-search';
import { LeapPerformanceSummary } from '../../model/leap-performance-summary';
import { LenovoProfile } from '../../model/lenovo/lenovo-profile';
import { UserProfile } from '../../model/user-profile';

@Injectable({
  providedIn: 'root',
})
export class UserRestService {
  constructor(private apiRest: ApiRestService) {}

  getAccountSummary(): Observable<AccountSummary> {
    return this.apiRest.get<AccountSummary>(`${environment.api.core.baseUrl}/members/users/account-summary`);
  }

  getYearAccountSummaries(): Observable<AccountSummary[]> {
    return this.apiRest.get<AccountSummary[]>(`${environment.api.core.baseUrl}/members/users/points/year-summaries`);
  }

  getLeapPerformanceSummary(): Observable<LeapPerformanceSummary> {
    return this.apiRest.get<LeapPerformanceSummary>(
      `${environment.api.core.baseUrl}/members/clients/lenovo/users/leap-performance`
    );
  }

  getProfile(): Observable<Profile> {
    return this.apiRest.get<Profile>(`${environment.api.core.baseUrl}/members/users/profile`);
  }

  getLenovoProfile(): Observable<LenovoProfile> {
    return this.apiRest.get<LenovoProfile>(`${environment.api.core.baseUrl}/members/clients/lenovo/users/profile`);
  }

  updateRole(profileRoleName: string): Observable<any> {
    return this.apiRest.post<any>(`${environment.api.core.baseUrl}/members/clients/lenovo/users/role`, {
      profileRoleName,
    });
  }

  searchTransactions(searchCriteria: TransactionSearch): Observable<SearchResult<Transaction>> {
    return this.apiRest.post<SearchResult<Transaction>>(
      `${environment.api.core.baseUrl}/members/transactions/search`,
      searchCriteria
    );
  }

  getTransactionTypes(): Observable<ReferenceData[]> {
    return this.apiRest.get<ReferenceData[]>(`${environment.api.core.baseUrl}/members/transactions/types`);
  }

  searchPromotions(searchCriteria: TransactionSearch): Observable<SearchResult<Transaction>> {
    return this.apiRest.post<SearchResult<Transaction>>(
      `${environment.api.core.baseUrl}/members/users/promotion-transactions`,
      searchCriteria
    );
  }

  searchRedeemedRewards(searchCriteria: RedemptionSearch): Observable<SearchResult<RedeemedReward>> {
    return this.apiRest.post<SearchResult<RedeemedReward>>(
      `${environment.api.core.baseUrl}/members/redemptions/search`,
      searchCriteria
    );
  }

  searchBookings(searchCriteria: BookingSearch): Observable<SearchResult<Booking>> {
    return this.apiRest.post<SearchResult<Booking>>(
      `${environment.api.core.baseUrl}/members/clients/amber/bookings/search`,
      searchCriteria
    );
  }

  getCommunicationPreferences(): Observable<any> {
    return this.apiRest.get(
      `${environment.api.core.baseUrl}/members/clients/lenovoleap/profile/communications-preferences`
    );
  }

  updateCommunicationPreferences(form: CommunicationsPreference): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/users/profile/send-email`, form);
  }

  acceptTermsConditions(): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/users/terms-and-conditions/accept`, null);
  }

  searchAvailableVenues(name: string): Observable<ReferenceData[]> {
    return this.apiRest.post<ReferenceData[]>(
      `${environment.api.core.baseUrl}/members/clients/amber/venues/search-available`,
      { name }
    );
  }

  updateVenue(venueId: number): Observable<void> {
    return this.apiRest.put<void>(`${environment.api.core.baseUrl}/members/clients/amber/userdetails/venue`, {
      venueId,
    });
  }

  setPassword(password: SetPassword) {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/users/set-password`, password);
  }

  createCheckoutSession(form: any): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/payments/create-checkout-session`, form);
  }

  getNotifications(): Observable<Notification[]> {
    return this.apiRest.get(`${environment.api.core.baseUrl}/members/notifications/unseen`);
  }

  setNotificationDisplayed(id: number): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/notifications/${id}/displayed`, null);
  }

  getUserCurrency(): Observable<any> {
    return this.apiRest.get(`${environment.api.core.baseUrl}/members/users/currency`);
  }

  getUserProfile(): Observable<any> {
    return this.apiRest.get(`${environment.api.core.baseUrl}/members/clients/lenovo/tradeup/profile`);
  }

  updateUserProfile(profile: UserProfile): Observable<any> {
    return this.apiRest.put(`${environment.api.core.baseUrl}/members/clients/lenovo/tradeup/profile`, profile);
  }
}
