import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-action-box',
  templateUrl: './action-box.component.html',
  styleUrls: ['./action-box.component.scss'],
})
export class ActionBoxComponent {
  @Input() imageUrl: string;
  @Input() title: string;
  @Input() url: string;

  @Output() trigger: EventEmitter<void> = new EventEmitter();
}
