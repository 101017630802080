<lib-generic-list
  [dataProvider]="dataProvider"
  [totalCount]="totalCount"
  [columnsProvider]="columnsProvider"
  [noResultsMessageDisplayKey]="'&nbsp;&nbsp;&nbsp;&nbsp;' + noResultsMessage | translate"
  [serverPaging]="true"
  [defaultPagination]="false"
  [tableStriped]="false"
  [actionsColumnLeft]="false"
  (stateChanged)="stateChangedHandler($event)"
>
</lib-generic-list>
