import { Component, Input, OnInit } from '@angular/core';
import { Cart, CartProduct, ProductCatalogueStoreService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-cart-widget',
  templateUrl: './cart-widget.component.html',
  styleUrls: ['./cart-widget.component.scss'],
})
export class CartWidgetComponent implements OnInit {
  @Input() showWishlistButton = true;

  cart: Cart | null;
  cartTotal: number;

  constructor(private productCatalogueStore: ProductCatalogueStoreService) {}

  ngOnInit(): void {
    this.productCatalogueStore.currentCart$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((cart: Cart) => {
      // deep copy cart
      this.cart = JSON.parse(JSON.stringify(cart));

      this.cartTotal = this.productCatalogueStore.getCartTotal();
    });

    this.productCatalogueStore.getCart();
  }

  removeItem(cartProduct: CartProduct): void {
    this.productCatalogueStore.removeProductFromCart(cartProduct.productId);
  }

  clearCart() {
    this.productCatalogueStore.resetCart();
  }
}
