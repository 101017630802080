<div class="wrapper">
  <ngb-carousel *ngIf="banners" [showNavigationArrows]="false">
    <ng-container *ngFor="let banner of banners">
      <ng-template ngbSlide>
        <div class="carousel-slide" *ngIf="banner.url && banner.url.includes('http')">
          <a [href]="banner.url" [target]="banner.target">
            <img [src]="banner.image" [alt]="banner.altText" class="w-100" />
            <div class="title" *ngIf="banner.title">
              <h1 [innerHTML]="banner.title"></h1>
              <div [innerHTML]="banner.subtitle"></div>
            </div>
          </a>
        </div>
        <div class="carousel-slide" *ngIf="banner.url && !banner.url.includes('http')">
          <a [routerLink]="banner.url" [target]="banner.target">
            <img [src]="banner.image" [alt]="banner.altText" class="w-100" />
            <div class="title" *ngIf="banner.title">
              <h1 [innerHTML]="banner.title"></h1>
              <div [innerHTML]="banner.subtitle"></div>
            </div>
          </a>
        </div>
        <div class="carousel-slide" *ngIf="!banner.url">
          <img [src]="banner.image" [alt]="banner.altText" class="w-100" />
          <div class="title" *ngIf="banner.title">
            <h1 [innerHTML]="banner.title"></h1>
            <div [innerHTML]="banner.subtitle"></div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ngb-carousel>
</div>
