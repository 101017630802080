import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-back-to-link',
  templateUrl: './back-to-link.component.html',
  styleUrls: ['./back-to-link.component.scss'],
})
export class BackToLinkComponent {
  @Input() routerLink: string;
  @Input() label: string;
}
