<footer class="footer">
  <div class="h-100 container-fluid">
    <div class="h-100 row">
      <div class="w-75 d-flex justify-content-start align-items-center">
        <div class="footer-item">
          <fa-icon class="me-1" [icon]="['fal', 'copyright']" size="xs"></fa-icon>
          <span>{{ this.year }} Lenovo</span>
        </div>
        <div class="footer-item">
          <a href="#" target="_blank">{{ 'Terms & Conditions' | translate }} </a>
        </div>
        <div class="footer-item">
          <a href="#" target="_blank">{{ 'Privacy' | translate }} </a>
        </div>
      </div>
    </div>
  </div>
</footer>
