<div class="container">
  <div class="row">
    <lib-banner
      [banner]="{
        imageUrl: banner,
        title: ''
      }"
    ></lib-banner>
    <div class="col-md-6 col-sm-12">
      <div class="mt-5 mb-2 h-100 w-100 container forgot-password-container">
        <h4 class="header">Reset your password</h4>
        <div class="forgot-password-form-container">
          <form
            [formGroup]="formGroup"
            class="d-flex flex-column align-items-center justify-content-center w-100"
            (ngSubmit)="onSubmit()"
          >
            <div class="pt-2 w-100 form-group" *ngIf="!verificationSent">
              <label>Email address</label>
              <input
                id="email"
                type="text"
                formControlName="email"
                placeholder="Email address"
                class="w-100 form-control"
              />
            </div>
            <div class="pt-2 w-100 form-group" *ngIf="verificationSent">
              <label>Verification code</label>
              <input
                id="verificationCode"
                type="text"
                formControlName="verificationCode"
                placeholder="Verification code"
                class="w-100 form-control"
              />
            </div>
            <div class="pt-2 w-100 form-group" *ngIf="verificationSent">
              <label>New password</label>
              <input
                id="newPassword"
                type="password"
                formControlName="newPassword"
                placeholder="New password"
                class="w-100 form-control"
              />
              <p>
                The password needs to be at least 8 characters long and contain 1 uppercase letter, 1 lowercase letter,
                1 number and 1 special character from the following = + - ^ $ * . [ ] ( ) ? ! @ # % & / \ , > ' : ; | _
                ~ `.
              </p>
            </div>
            <div class="pt-2 w-100 form-group" *ngIf="verificationSent">
              <label>Repeat password</label>
              <input
                id="confirmedPassword"
                type="password"
                formControlName="confirmedPassword"
                placeholder="Repeat password"
                class="w-100 form-control"
              />
            </div>
            <div class="d-flex w-100 mt-4">
              <button class="px-5 btn btn-primary" type="submit">
                {{ verificationSent ? 'Submit' : 'Get verification code' }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
