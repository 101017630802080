import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractDialogComponent, MxLoggerService } from '@motivforce/mx-library-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, interval } from 'rxjs';
import { skip, take, takeUntil, timeInterval } from 'rxjs/operators';
import { Puzzle } from 'src/app/core/model/promotion/puzzle';
import { PromotionStoreService } from 'src/app/core/store/promotion-store.service';
import { PuzzleStoreService } from 'src/app/core/store/puzzle-store.service';
import { UserStoreService } from 'src/app/core/store/user-store.service';

@Component({
  selector: 'app-promotion-pow-dialog',
  templateUrl: './promotion-pow-dialog.component.html',
  styleUrls: ['./promotion-pow-dialog.component.scss'],
})
export class PromotionPowDialogComponent extends AbstractDialogComponent implements OnInit, OnDestroy {
  context: 'homePage' | 'promotionPage';
  mode: 'prompt' | 'puzzle' | 'result' = 'prompt';
  modalWidth: 'lg' | 'xl' = 'lg';

  puzzle: Puzzle;

  resultTitle: string;
  resultMessage: string;

  onIntervalStop$: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private puzzleStore: PuzzleStoreService,
    private promotionStore: PromotionStoreService,
    private mxLogger: MxLoggerService,
    private userStore: UserStoreService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.inputs && this.inputs.length > 0) {
      this.context = this.inputs[0];
    }

    this.puzzleStore.currentPuzzle$.pipe(skip(1), take(1)).subscribe((puzzle) => {
      if (puzzle) {
        this.puzzle = puzzle;
      }
    });

    this.puzzleStore.getPuzzle(this.promotionStore.currentPow!.promotionSpecificId);
  }

  ngOnDestroy(): void {
    this.stopInterval();
  }

  startPuzzle(): void {
    this.mxLogger.debug(
      'PromotionPuzzleDialogComponent',
      'startPuzzle() this.promotionStore.currentPow=',
      this.promotionStore.currentPow
    );

    this.puzzleStore.startPuzzle(this.promotionStore.currentPow!.promotionSpecificId).then((success) => {
      if (success) {
        this.puzzle = this.puzzleStore.currentPuzzle!;

        this.mode = 'puzzle';

        this.resetModalWidth();

        this.puzzle.timeElapsed = 0;

        const eachSecondInterval = interval(1000);

        eachSecondInterval.pipe(takeUntil(this.onIntervalStop$), timeInterval()).subscribe((time) => {
          this.puzzle.timeElapsed = time.value + 1;
        });
      }
    });
  }

  private resetModalWidth(): void {
    this.modalWidth =
      this.mode === 'puzzle' && this.puzzle && this.puzzle.imageWidth / this.puzzle.imageHeight > 4 / 3 ? 'xl' : 'lg';
    const modalDialog = document.getElementsByClassName('modal-dialog')[0];
    modalDialog.classList.remove('modal-xl');
    modalDialog.classList.remove('modal-lg');
    modalDialog.classList.add(`modal-${this.modalWidth}`);
  }

  private stopInterval(): void {
    this.onIntervalStop$.next(null);
    this.onIntervalStop$.complete();
  }

  submitPuzzle(): void {
    this.mxLogger.trace('PromotionPuzzleDialogComponent', 'submitPuzzle() this.puzzle.isSolved=', this.puzzle.isSolved);

    this.stopInterval();

    this.puzzleStore.currentPuzzle$.pipe(skip(1), take(1)).subscribe((puzzle) => {
      this.puzzle = puzzle!;

      this.mode = 'result';

      this.resetModalWidth();

      // eslint-disable-next-line default-case
      switch (this.puzzle.statistics.result) {
        case 'Correct':
          this.resultTitle = 'Congratulations';
          this.resultMessage = `You have earned ${this.puzzle.statistics.points} bonus points, good luck next week!`;
          break;
        case 'Incorrect':
          this.resultTitle = 'Sorry';
          this.resultMessage = `Better luck next time.`;
          break;
        case 'AttemptsExceed':
          this.resultTitle = 'Sorry';
          this.resultMessage = `Attempts exceeded.`;
          break;
        case 'Timeout':
          this.resultTitle = 'Sorry';
          this.resultMessage = `Time out`;
          break;
      }

      this.promotionStore.finishPromotion(this.promotionStore.currentPow!.id).then(() => {
        this.userStore.getAccountSummary();
      });
    });
    this.puzzleStore.submitPuzzle(this.puzzle);
  }

  cancelPuzzle(): void {
    this.stopInterval();

    this.puzzleStore.currentPuzzle$.pipe(skip(1), take(1)).subscribe(() => {
      this.activeModal.close('cancel');
      this.promotionStore.finishPromotion(this.promotionStore.currentPow!.id);
    });

    this.puzzle.isSolved = null;
    this.puzzleStore.submitPuzzle(this.puzzle);
  }
}
