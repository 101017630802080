import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ContentStoreService, MxLoggerService, AuthStoreService, NavMenu } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { Notification } from 'src/app/core/model/core/notification';
import { PromotionStoreService } from 'src/app/core/store/promotion-store.service';
import { UserStoreService } from 'src/app/core/store/user-store.service';
import { environment } from 'src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('header', { static: true }) headerElement: ElementRef<HTMLElement>;

  headerFixed = environment.headerFixed;

  authenticatedUser$ = this.authStore.authenticatedUser$.pipe(untilDestroyed(this));
  notifications: Notification[] = [];

  headerMenu: NavMenu;

  constructor(
    private authStore: AuthStoreService,
    private renderer: Renderer2,
    private contentStore: ContentStoreService,
    private mxLogger: MxLoggerService,
    private promotionStore: PromotionStoreService,
    private userStore: UserStoreService
  ) {}

  ngOnInit(): void {
    this.contentStore.headerMenu$.pipe(filter(Boolean)).subscribe((headerMenu) => {
      this.mxLogger.debug('HeaderComponent', 'ngOnInit() headerMenu=', headerMenu);

      this.headerMenu = headerMenu;
    });
  }

  onNotificationClick(notificationId: number): void {
    this.userStore.setNotificationDisplayed(notificationId);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    if (environment.headerFixed) {
      if (window.scrollY > 0) {
        this.renderer.addClass(this.headerElement.nativeElement, 'scrolled');
      } else {
        this.renderer.removeClass(this.headerElement.nativeElement, 'scrolled');
      }
    }
  }
}
