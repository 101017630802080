<app-default-main-layout>
  <div class="d-flex flex-column wrapper">
    <div class="container">
      <div class="row h-100">
        <div
          class="d-flex flex-column h-100 justify-content-start mt-5"
          [ngClass]="{
            'col-12 col-sm-10': showAccountSummaryWidget || showQuickLinks,
            'col-12': !(showAccountSummaryWidget || showQuickLinks)
          }"
        >
          <ng-content></ng-content>
        </div>
        <div
          class="col-2 h-100 d-none d-lg-flex flex-column align-items-center align-items-lg-start"
          *ngIf="showAccountSummaryWidget || showQuickLinks"
        >
          <app-account-summary-widget
            *ngIf="showAccountSummaryWidget"
            [fixedToScrolling]="accountSummaryWidgetFixedToScrolling"
          >
            <div class="d-flex justify-content-center" *ngIf="showViewWishlist">
              <a class="btn btn-primary btn-secondary w-100" [routerLink]="'/product-catalogue/wishlist'"
                >VIEW WISHLIST</a
              >
            </div>
            <div class="d-flex justify-content-center" *ngIf="showViewCart">
              <a class="btn btn-primary btn-secondary w-100" [routerLink]="'/rewards-catalogue/cart'">VIEW CART</a>
            </div>
          </app-account-summary-widget>
          <div class="w-100 h-100 pt-5 d-flex flex-column justify-content-center" *ngIf="showQuickLinks">
            <div class="pb-2 d-flex justify-content-center">
              <h5>Quick Links</h5>
            </div>

            <div class="my-1 d-flex justify-content-center">
              <a class="w-100 btn btn-primary btn-secondary" [routerLink]="quickLinkRoute">{{ quickLinkName }}</a>
            </div>
            <div class="my-1 d-flex justify-content-center">
              <a class="btn btn-primary btn-secondary w-100" href="#" target="_blank">Terms and Conditions</a>
            </div>
            <div class="my-1 d-flex justify-content-center">
              <a class="btn btn-primary btn-secondary w-100" [routerLink]="'/support/contact-us'">Contact Us</a>
            </div>
          </div>
        </div>

        <div
          class="col-2 h-100 d-none d-sm-flex d-lg-none flex-column align-items-center align-items-lg-start"
          *ngIf="showAccountSummaryWidget || showQuickLinks"
        >
          <app-account-summary-widget *ngIf="showAccountSummaryWidget" [fixedToScrolling]="false">
            <div class="d-flex justify-content-center" *ngIf="showViewWishlist">
              <a class="btn btn-primary btn-secondary w-100" [routerLink]="'/product-catalogue/wishlist'"
                >VIEW WISHLIST</a
              >
            </div>
            <div class="d-flex justify-content-center" *ngIf="showViewCart">
              <a class="btn btn-primary btn-secondary w-100" [routerLink]="'/rewards-catalogue/cart'">VIEW CART</a>
            </div>
          </app-account-summary-widget>
          <div class="w-100 h-100 pt-5 d-flex flex-column justify-content-center" *ngIf="showQuickLinks">
            <div class="pb-2 d-flex justify-content-center">
              <h5>Quick Links</h5>
            </div>

            <div class="my-1 d-flex justify-content-center">
              <a class="w-100 btn btn-primary btn-secondary" [routerLink]="quickLinkRoute">{{ quickLinkName }}</a>
            </div>
            <div class="my-1 d-flex justify-content-center">
              <a class="btn btn-primary btn-secondary w-100" href="#" target="_blank">Terms and Conditions</a>
            </div>
            <div class="my-1 d-flex justify-content-center">
              <a class="btn btn-primary btn-secondary w-100" [routerLink]="'/support/contact-us'">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-default-main-layout>
