import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthStoreService, MxLoggerService } from '@motivforce/mx-library-angular';
import { skip, take } from 'rxjs/operators';

@Component({
  selector: 'app-login-redirect-container',
  templateUrl: './login-redirect-container.component.html',
  styleUrls: ['./login-redirect-container.component.scss'],
})
export class LoginRedirectContainerComponent implements OnInit {
  constructor(private authStore: AuthStoreService, private mxLogger: MxLoggerService, private router: Router) {}

  ngOnInit(): void {
    // this.mxLogger.debug(
    //   'LoginRedirectContainerComponent',
    //   'ngOnInit() this.authStore.authenticatedUser=',
    //   this.authStore.authenticatedUser
    // );

    this.authStore.authenticatedUser$.pipe(skip(1), take(1)).subscribe((authenticatedUser) => {
      this.mxLogger.trace('LoginRedirectContainerComponent', 'ngOnInit() NEW authenticatedUser=', authenticatedUser);
      this.router.navigate(['/home']);
    });

    // if (!this.authStore.authenticatedUser) {
    //   this.authStore.federatedSignIn(environment.oidc.provider);
    // }

    this.authStore.getCurrentAuthenticatedUser();
  }
}
