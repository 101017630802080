import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { GenericListColumn, GenericListState } from '@motivforce/mx-library-angular';

@Component({
  selector: 'app-custom-list',
  templateUrl: './custom-list.component.html',
  styleUrls: ['./custom-list.component.scss'],
})
export class CustomListComponent implements AfterViewInit {
  @Input() dataProvider: any[] = [];
  @Input() columnsProvider: GenericListColumn[] = [];
  @Input() noResultsMessage = 'No records found.';

  private _totalCount: number;

  @Output() stateChanged: EventEmitter<GenericListState> = new EventEmitter();

  page = 1;
  pageSize = 10;

  constructor(@Inject(DOCUMENT) protected document: Document) {}

  ngAfterViewInit(): void {
    this.updatePagingLabels();
  }

  @Input()
  get totalCount(): number {
    return this._totalCount;
  }

  set totalCount(value: number) {
    this._totalCount = value;

    this.updatePagingLabels();
  }

  stateChangedHandler(event: any): void {
    this.page = event.page;
    this.pageSize = event.pageSize;

    this.stateChanged.emit(event);
  }

  private updatePagingLabels(): void {
    const pageSizeBeforeElement = this.document.body.querySelector('.page-options-size div:nth-child(1)');

    if (pageSizeBeforeElement) {
      pageSizeBeforeElement.innerHTML = 'Items per page:';
    }

    const pageSizeAfterElement = this.document.body.querySelector('.page-options-size div:nth-child(3)');

    if (pageSizeAfterElement) {
      pageSizeAfterElement.innerHTML = this.totalCount
        ? `${(this.page - 1) * this.pageSize + 1} - ${this.page * this.pageSize} of ${this.totalCount} items.`
        : '';
    }
  }
}
