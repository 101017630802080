<div class="wrapper">
  <div class="container d-none d-lg-block">
    <lib-banner [banner]="banner"></lib-banner>
  </div>
  <lib-banner class="d-lg-none" [banner]="banner"></lib-banner>

  <lib-breadcrumbs class="d-none d-lg-block" [delimiter]="'>'"></lib-breadcrumbs>

  <div class="container">
    <div class="mt-5 mt-lg-4 row">
      <div class="col-12">
        <div class="title" [innerHtml]="banner?.title"></div>
      </div>
    </div>
  </div>
  <div class="mt-4 mb-5 content-wrapper">
    <ng-content></ng-content>
  </div>

  <div class="side-widgets" *ngIf="showSideWidgets">
    <app-account-summary-widget></app-account-summary-widget>
    <hr />
    <app-cart-widget></app-cart-widget>
  </div>
</div>
