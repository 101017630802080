import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractDialogComponent,
  MxLoggerService,
  Quiz,
  QuizQuestionAnswer,
  QuizStoreService,
} from '@motivforce/mx-library-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { interval, Subject } from 'rxjs';
import { skip, take, takeUntil, timeInterval } from 'rxjs/operators';
import { PromotionStoreService } from 'src/app/core/store/promotion-store.service';
import { UserStoreService } from 'src/app/core/store/user-store.service';

@Component({
  selector: 'app-promotion-qow-dialog',
  templateUrl: './promotion-qow-dialog.component.html',
  styleUrls: ['./promotion-qow-dialog.component.scss'],
})
export class PromotionQowDialogComponent extends AbstractDialogComponent implements OnInit, OnDestroy {
  context: 'homePage' | 'promotionPage';
  mode: 'prompt' | 'question' | 'result' = 'prompt';

  quiz: Quiz;
  currentAnswer: QuizQuestionAnswer;

  resultTitle = 'Title';
  resultMessage = 'Big long super message about QOW!';

  onIntervalStop$: Subject<any> = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    private quizStore: QuizStoreService,
    private promotionStore: PromotionStoreService,
    private mxLogger: MxLoggerService,
    private userStore: UserStoreService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.inputs && this.inputs.length > 0) {
      this.context = this.inputs[0];
    }
  }

  ngOnDestroy(): void {
    this.stopInterval();
  }

  startQow(): void {
    this.mxLogger.debug(
      'PromotionQowDialogComponent',
      'startQow() this.promotionStore.currentQow=',
      this.promotionStore.currentQow
    );

    this.quizStore.currentQuiz$.pipe(skip(1), take(1)).subscribe((quiz) => {
      this.quizStore.startQuiz(this.promotionStore.currentQow!.promotionSpecificId).then((success) => {
        if (success) {
          this.mode = 'question';

          this.quiz = quiz!;
          this.quiz.timeElapsed = 0;

          const eachSecondInterval = interval(1000);

          eachSecondInterval.pipe(takeUntil(this.onIntervalStop$), timeInterval()).subscribe((time) => {
            this.quiz!.timeElapsed = time.value + 1;
          });
        }
      });
    });

    this.quizStore.getQuiz(this.promotionStore.currentQow!.promotionSpecificId);
  }

  private stopInterval(): void {
    this.onIntervalStop$.next(null);
    this.onIntervalStop$.complete();
  }

  submitQow(): void {
    this.stopInterval();

    this.quizStore.currentQuiz$.pipe(skip(1), take(1)).subscribe((quiz) => {
      this.mode = 'result';

      // eslint-disable-next-line default-case
      switch (quiz!.statistics.result) {
        case 'Correct':
          this.resultTitle = 'Congratulations';
          this.resultMessage = `You have earned ${this.quiz.statistics.points} bonus points, good luck next week!`;
          break;
        case 'Incorrect':
          this.resultTitle = 'Sorry';
          this.resultMessage = `Better luck next time.`;
          break;
        case 'AttemptsExceed':
          this.resultTitle = 'Sorry';
          this.resultMessage = `Attempts exceeded.`;
          break;
        case 'Timeout':
          this.resultTitle = 'Sorry';
          this.resultMessage = `Time out`;
          break;
      }

      this.promotionStore.finishPromotion(this.promotionStore.currentQow!.id).then(() => {
        this.userStore.getAccountSummary();
      });
    });

    this.quizStore.submitQuiz(this.quiz);
  }

  cancelQow(): void {
    this.stopInterval();

    this.quizStore.currentQuiz$.pipe(skip(1), take(1)).subscribe(() => {
      this.activeModal.close('cancel');

      this.promotionStore.finishPromotion(this.promotionStore.currentQow!.id);
    });

    if (this.quiz!.questions && this.quiz!.questions.length > 0) {
      this.quiz!.questions[0].userAnswerId = undefined;
    }

    this.quizStore.submitQuiz(this.quiz);
  }
}
