import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CartProduct } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, takeUntil, filter } from 'rxjs';

import { CustomListComponent } from '../custom-list/custom-list.component';

@UntilDestroy()
@Component({
  selector: 'app-custom-product-list',
  templateUrl: './custom-product-list.component.html',
  styleUrls: ['./custom-product-list.component.scss'],
})
export class CustomProductListComponent extends CustomListComponent implements OnInit, OnChanges {
  static MAX_QUANTITY = 10;

  quantityDataProvider: any[] = [];

  @Input() editable = true;

  @Output() quantityChange: EventEmitter<CartProduct> = new EventEmitter();
  @Output() itemRemove: EventEmitter<number> = new EventEmitter();

  private itemQuantityFormControls: Record<number, UntypedFormControl> = {};
  private resetFormControlsSubject = new Subject();

  ngOnInit() {
    for (let i = 0; i < CustomProductListComponent.MAX_QUANTITY; i += 1) {
      this.quantityDataProvider.push({ label: i, value: i });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dataProvider']) {
      this.resetItemQuantityFormControls(changes['dataProvider'].currentValue);
    }
  }

  private resetItemQuantityFormControls(products: CartProduct[]) {
    if (products) {
      this.resetFormControlsSubject.next(null);
      this.resetFormControlsSubject.complete();

      this.resetFormControlsSubject = new Subject();
      this.itemQuantityFormControls = {};

      products.forEach((item) => {
        this.itemQuantityFormControls[item.id] = new UntypedFormControl(item.quantity);
        this.itemQuantityFormControls[item.id].valueChanges
          .pipe(
            untilDestroyed(this),
            takeUntil(this.resetFormControlsSubject),
            filter(() => this.itemQuantityFormControls[item.id].dirty)
          )
          .subscribe((value) => {
            item.quantity = value;
            this.quantityChange.emit(item);
          });
      });
    }
  }

  getQuantityFormControl(item: CartProduct): UntypedFormControl {
    return this.itemQuantityFormControls[item.id] as UntypedFormControl;
  }

  decrementQuantity(item: CartProduct) {
    const cartProduct = { ...item };
    cartProduct.quantity -= 1;

    this.quantityChange.emit(cartProduct);
  }

  incrementQuantity(item: CartProduct) {
    const cartProduct = { ...item };
    cartProduct.quantity += 1;

    this.quantityChange.emit(cartProduct);
  }
}
