import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthStoreService } from '@motivforce/mx-library-angular';
import { Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(public authStore: AuthStoreService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(environment.api.core.baseUrl)) {
      if (this.authStore.userSession) {
        const token = this.authStore.userSession.getIdToken().getJwtToken();
        const headers = {
          Authorization: `Bearer ${token}`,
        } as any;
        const impersonateUserId = this.authStore.getImpersonatedUserId();
        if (impersonateUserId) {
          headers['admin-impersonate-user-id'] = impersonateUserId;
        }

        request = request.clone({
          setHeaders: headers,
        });

        return next.handle(request);
      }
    }

    return next.handle(request);
  }
}
