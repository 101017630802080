<div
  [ngClass]="{
    'modal-open-wrapper-10': scrollBarWidth === 10,
    'modal-open-wrapper-11': scrollBarWidth === 11,
    'modal-open-wrapper-12': scrollBarWidth === 12,
    'modal-open-wrapper-13': scrollBarWidth === 13,
    'modal-open-wrapper-14': scrollBarWidth === 14,
    'modal-open-wrapper-15': scrollBarWidth === 15,
    'modal-open-wrapper-16': scrollBarWidth === 16,
    'modal-open-wrapper-17': scrollBarWidth === 17,
    'modal-open-wrapper-18': scrollBarWidth === 18,
    'modal-open-wrapper-19': scrollBarWidth === 19,
    'modal-open-wrapper-20': scrollBarWidth === 20,
    'modal-open-wrapper-21': scrollBarWidth === 21,
    'modal-open-wrapper-22': scrollBarWidth === 22,
    'modal-open-wrapper-23': scrollBarWidth === 23,
    'modal-open-wrapper-24': scrollBarWidth === 24
  }"
>
  <ng-content></ng-content>
</div>
