import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AccountSummary } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { UserStoreService } from 'src/app/core/store/user-store.service';

@UntilDestroy()
@Component({
  selector: 'app-promotion-animated-button',
  templateUrl: './promotion-animated-button.component.html',
  styleUrls: ['./promotion-animated-button.component.scss'],
  animations: [
    trigger('show', [
      state(
        'shown',
        style({
          top: '500px',
        })
      ),
      state(
        'hidden',
        style({
          top: '100vh',
        })
      ),
      transition('shown => hidden', animate('1000ms ease-in')),
      transition('hidden => shown', animate('8000ms cubic-bezier(0.33, 1, 0.68, 1)')),
    ]),
  ],
})
export class PromotionAnimatedButtonComponent implements OnInit {
  @Output() trigger: EventEmitter<void> = new EventEmitter();

  showState: 'shown' | 'hidden' = 'hidden';

  accountSummary: AccountSummary;

  constructor(private userStore: UserStoreService) {}

  ngOnInit(): void {
    this.userStore.accountSummary$
      .pipe(filter(Boolean), untilDestroyed(this))
      .subscribe((accountSummary: AccountSummary) => {
        this.accountSummary = accountSummary;
        if (this.accountSummary.availableBalance) {
          this.showState = this.accountSummary.availableBalance > 0 ? 'shown' : 'hidden';
        }
      });
  }
}
