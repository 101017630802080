import { Component, Input, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DialogNgbService, MxLoggerService, Content, ContentDynamicLayout } from '@motivforce/mx-library-angular';
import { UserStoreService } from 'src/app/core/store/user-store.service';

@Component({
  selector: 'app-set-password-content-template',
  templateUrl: './set-password-content-template.component.html',
  styleUrls: ['./set-password-content-template.component.scss'],
})
export class SetPasswordContentTemplateComponent implements OnInit, ContentDynamicLayout {
  @Input() baseContent: Content;
  banner: string;

  userId: number | null;
  identifier: string | null;
  showForm = true;

  formGroup = this.formBuilder.group({
    newPassword: [
      null,
      [Validators.required, Validators.pattern('^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[^\\w\\d])(?=.*\\d).*$')],
    ],
    confirmedPassword: [null, [Validators.required]],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userStore: UserStoreService,
    private dialogService: DialogNgbService,
    private mxLogger: MxLoggerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    this.banner = bannerField!.value.url;
    this.userId = Number(this.route.snapshot.queryParamMap.get('userId'));
    this.identifier = this.route.snapshot.queryParamMap.get('identifier');

    if (this.userId === null || this.userId === 0 || this.identifier === null) {
      this.showForm = false;
      this.dialogService.openError(['Invalid link'], 'Important');
    }
  }

  onSubmit(): void {
    const formValue = this.formGroup.value;

    if (this.formGroup.valid) {
      if (formValue.newPassword === formValue.confirmedPassword) {
        this.userStore.setPassword({
          userId: this.userId!,
          identifier: this.identifier!,
          password: formValue.newPassword,
        });
      } else {
        this.dialogService.openError(['Passwords do not match.'], 'Important');
        this.mxLogger.trace('SetPasswordContentTemplateComponent', 'Passwords do not match.');
      }
    } else {
      if (this.formGroup.controls['newPassword'].errors) {
        const passwordRequirements =
          // eslint-disable-next-line prettier/prettier
          "The password needs to be at least 8 characters long and contain 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character from the following = + - ^ $ * . [ ] { } ( ) ? ! @ # % & / \\ , > ' : ; | _ ~ `.";

        this.dialogService.openError([passwordRequirements], 'Important');
      } else if (this.formGroup.controls['confirmedPassword'].errors) {
        this.dialogService.openError(['You need to enter Confirm Password.'], 'Important');
      }

      this.mxLogger.trace('SetPasswordContentTemplateComponent', 'The form is invalid.');
    }
  }
}
