import { Component, Input, OnInit } from '@angular/core';
import { Content, ContentDynamicLayout } from '@motivforce/mx-library-angular';

@Component({
  selector: 'app-default-content-template',
  templateUrl: './default-content-template.component.html',
  styleUrls: ['./default-content-template.component.scss'],
})
export class DefaultContentTemplateComponent implements OnInit, ContentDynamicLayout {
  @Input() baseContent: Content;

  body: string;
  title: string;
  banner: string;

  ngOnInit(): void {
    const titleField = this.baseContent.fields.find((f) => f.name === 'Title');
    const bannerField = this.baseContent.fields.find((f) => f.name === 'Banner');
    const bodyField = this.baseContent.fields.find((f) => f.name === 'Body');

    this.title = titleField!.value.value;
    this.body = bodyField!.value.value;
    this.banner = bannerField!.value.url;
  }
}
