<ng-container *ngIf="mode === 'prompt'"
  ><h3 class="pt-3 text-uppercase header">Do you want to take the challenge?</h3>
  <h1 class="text-uppercase header">Puzzle of the week</h1>
  <h3 class="text-uppercase header">You'll get extra points rewarded</h3>
</ng-container>
<div
  class="w-100 d-flex justify-content-center align-items-center"
  [ngClass]="{ 'h-100': mode !== 'puzzle', 'pt-2': mode === 'prompt', 'pb-3': mode === 'prompt' }"
  *ngIf="puzzle"
>
  <div
    class="d-flex flex-column justify-content-around align-items-center pow-content"
    [ngClass]="{ 'pow-content-puzzle': mode === 'puzzle' }"
    #puzzleContainer
  >
    <ng-container *ngIf="mode === 'prompt'">
      <div class="w-100 p-4">
        <h5 class="m-2">{{ puzzle.title }}</h5>
      </div>

      <div class="w-100 pl-4 pr-4">
        <p class="m-2">{{ puzzle.description }}</p>
      </div>

      <div class="w-75 p-3 mt-2">
        <h6 class="m-3 text-uppercase text-center">
          <strong>Do you want to take the puzzle of the week challenge?</strong>
        </h6>
      </div>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <button class="btn btn-primary" type="button" (click)="startPuzzle()">YES</button>
        <button class="btn btn-primary ms-4" type="button" (click)="activeModal.close('no')">NO</button>
      </div>

      <div class="w-100 p-3 text-center" *ngIf="context === 'homePage'">
        <a [routerLink]="'/promotions/pow'" (click)="activeModal.close('no')">Previous Results</a>
      </div>
    </ng-container>
    <ng-container *ngIf="mode === 'puzzle'">
      <!-- TODO add for time elapsed like QOW or rm -->
      <!-- <div class="w-100 p-3 d-flex justify-content-end align-items-center">
            <fa-icon [icon]="['far', 'clock']" size="xs"></fa-icon>
            <span class="ms-1 elapsed-seconds">{{ quiz.timeElapsed }}</span>
          </div> -->
      <div class="w-100 p-4">
        <h5 class="m-2">{{ puzzle.title }}</h5>
      </div>

      <div class="w-100 pb-3 d-flex justify-content-around align-items-center">
        <app-image-slider-puzzle
          [imageUrl]="puzzle.imageUrl"
          [imageWidth]="(modalWidth === 'xl' ? 0.8 : 0.65) * puzzleContainer.offsetWidth"
          [imageHeight]="
            (puzzle.imageHeight * (modalWidth === 'xl' ? 0.8 : 0.65) * puzzleContainer.offsetWidth) / puzzle.imageWidth
          "
          [columnCount]="puzzle.columnsCount"
          [rowCount]="puzzle.rowsCount"
          (puzzleComplete)="this.puzzle.isSolved = $event"
        ></app-image-slider-puzzle>
      </div>

      <div class="w-100 pb-3 d-flex justify-content-center align-items-center">
        <button class="btn btn-primary" type="button" (click)="submitPuzzle()">Submit</button>
        <button class="btn btn-primary ms-4" type="button" (click)="cancelPuzzle()">Cancel</button>
      </div>
    </ng-container>
    <ng-container *ngIf="mode === 'result'">
      <div class="w-75 p-2 mt-4">
        <h5 class="m-2 text-uppercase text-center">
          <strong>{{ resultTitle }}</strong>
        </h5>
      </div>

      <div class="w-100 p-5 d-flex justify-content-around align-items-center text-center result-message">
        {{ resultMessage }}
      </div>

      <div class="w-100 pb-5 d-flex justify-content-around align-items-center">
        <button class="btn btn-primary" type="button" (click)="activeModal.close()">Close</button>
      </div>
    </ng-container>
  </div>
</div>
