import { Injectable } from '@angular/core';
import { ApiRestService } from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Puzzle } from '../../model/promotion/puzzle';

@Injectable({
  providedIn: 'root',
})
export class PuzzleRestService {
  constructor(protected apiRestService: ApiRestService) {}

  getPuzzle(puzzleId: number): Observable<Puzzle> {
    return this.apiRestService.get<Puzzle>(`${environment.api.core.baseUrl}/members/puzzles/${puzzleId}`, {});
  }

  startPuzzle(puzzleId: number): Observable<any> {
    return this.apiRestService.post<void>(`${environment.api.core.baseUrl}/members/puzzles/${puzzleId}/start`, {});
  }

  submitPuzzle(puzzle: Puzzle): Observable<Puzzle> {
    return this.apiRestService.post<Puzzle>(
      `${environment.api.core.baseUrl}/members/puzzles/${puzzle.id}/submit`,
      puzzle
    );
  }
}
