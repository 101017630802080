<div class="outer" id="outer">
  <div class="hidden" id="hidden"></div>
  <div class="puzzle-container" dropzone="true" [ngStyle]="{ width: imageWidth + 'px', height: imageHeight + 'px' }">
    <div
      *ngFor="let imageTile of imageTiles"
      class="box"
      [draggable]="true"
      [id]="imageTile.index"
      (dragstart)="onDragStart($event)"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (dragend)="onDragEnd()"
      [style.width.px]="boxWidth"
      [style.height.px]="boxHeight"
      [ngStyle]="{
        'background-image': 'url(' + imageUrl + ') ',
        'background-repeat': 'no-repeat',
        'background-position': '' + imageTile.xPercentPosition + ' ' + imageTile.yPercentPosition + '',
        'background-size': '' + columnCount * 100 + '% ' + rowCount * 100 + '%'
      }"
    ></div>
  </div>
</div>
