import { Injectable } from '@angular/core';
import { IsLoadingService } from '@service-work/is-loading';
import { BehaviorSubject } from 'rxjs';

import { PuzzleRestService } from '../api/rest/puzzle-rest.service';
import { Puzzle } from '../model/promotion/puzzle';

@Injectable({
  providedIn: 'root',
})
export class PuzzleStoreService {
  private readonly _currentPuzzle = new BehaviorSubject<Puzzle | null>(null);
  readonly currentPuzzle$ = this._currentPuzzle.asObservable();

  constructor(private puzzleRest: PuzzleRestService, private isLoadingService: IsLoadingService) {}

  get currentPuzzle(): Puzzle | null {
    return this._currentPuzzle.getValue();
  }

  getPuzzle(puzzled: number): void {
    this.isLoadingService.add(
      this.puzzleRest.getPuzzle(puzzled).subscribe((puzzle: Puzzle) => {
        this._currentPuzzle.next(puzzle);
      })
    );
  }

  startPuzzle(puzzled: number): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.puzzleRest.startPuzzle(puzzled).subscribe(
          () => resolve(true),
          () => resolve(false)
        )
      );
    });
  }

  submitPuzzle(puzzle: Puzzle): void {
    this.isLoadingService.add(
      this.puzzleRest.submitPuzzle(puzzle).subscribe((puzzleResponse: Puzzle) => {
        this._currentPuzzle.next(puzzleResponse);
      })
    );
  }
}
