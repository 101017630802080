<div class="modal-header">
  <h1 class="modal-title" id="modal-basic-title">Welcome to the Lenovo Trade Up, your loyalty program</h1>
</div>
<div class="modal-body">
  <div class="prompt">
    Please accept the
    <a
      href="https://tradeupsite-assets.s3.eu-west-2.amazonaws.com/assets/DCG+Channels+Trade+Up+120321.pdf"
      target="_blank"
      >terms and conditions</a
    >
    to access your loyalty program.
  </div>

  <div class="w-100 mt-4">
    <form class="d-flex align-items-center justify-content-center flex-column">
      <div class="form-row w-100">
        <div class="d-flex align-items-center terms-form">
          <input type="checkbox" id="tnCs" [(ngModel)]="tnCsChecked" [ngModelOptions]="{ standalone: true }" />
          <label for="tnCs">
            I have read and agreed to the
            <a
              href="https://tradeupsite-assets.s3.eu-west-2.amazonaws.com/assets/DCG+Channels+Trade+Up+120321.pdf"
              target="_blank"
              >Lenovo Trade Up Terms and Conditions</a
            >
          </label>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="onSubmit()">Accept</button>
</div>
