<div
  class="d-none d-lg-block position-fixed wrapper"
  [@show]="showState"
  (click)="trigger.emit()"
  *ngIf="accountSummary"
>
  <h4>POINTS BALANCE</h4>
  <p>You have {{ accountSummary.availableBalance }} points available to redeem.</p>
  <h4 class="link">Redeem now!</h4>
</div>
