<lib-banner
  [banner]="{
    imageUrl: banner,
    title: ''
  }"
></lib-banner>
<div class="main-wrapper w-100 container set-password-container">
  <div class="row">
    <div class="col-sm-12 text-center"></div>
  </div>
  <div class="set-password-form-container" *ngIf="showForm">
    <form
      [formGroup]="formGroup"
      class="d-flex flex-column align-items-center justify-content-center w-100"
      (ngSubmit)="onSubmit()"
    >
      <div class="form-row p-1 w-50">
        <input
          id="newPassword"
          type="password"
          formControlName="newPassword"
          placeholder="New password"
          class="w-100"
        />
        <p>
          The password needs to be at least 8 characters long and contain 1 uppercase letter, 1 lowercase letter, 1
          number and 1 special character from the following = + - ^ $ * . [ ] ( ) ? ! @ # % & / \ , > ' : ; | _ ~ `.
        </p>
      </div>
      <div class="form-row p-1 w-50">
        <input
          id="confirmedPassword"
          type="password"
          formControlName="confirmedPassword"
          placeholder="Confirm password"
          class="w-100"
        />
      </div>
      <div class="form-row w-75 pt-4">
        <div class="d-flex align-items-center justify-content-center w-100">
          <button class="btn btn-primary" type="submit">SAVE PASSWORD</button>
        </div>
      </div>
    </form>
  </div>
</div>
