import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-image-slider-puzzle',
  templateUrl: './image-slider-puzzle.component.html',
  styleUrls: ['./image-slider-puzzle.component.scss'],
})
export class ImageSliderPuzzleComponent implements OnInit, OnChanges {
  @Input() imageUrl: string;
  @Input() imageWidth = 600;
  @Input() imageHeight = 600;
  @Input() rowCount = 4;
  @Input() columnCount = 4;

  @Output() puzzleChange: EventEmitter<any> = new EventEmitter();
  @Output() puzzleComplete: EventEmitter<boolean> = new EventEmitter();

  boxWidthRatio: number = 100 / (this.columnCount - 1);
  boxHeightRatio: number = 100 / (this.rowCount - 1);

  boxWidth: number;
  boxHeight: number;

  totalBoxes: number = this.columnCount * this.rowCount;

  imageTiles: { xPercentPosition: string; yPercentPosition: string; index: number }[] = [];

  steps = 0;

  indexes: number[] = [];
  positions: number[] = [];

  draggedId: number | null;
  dragCloneElement: any;

  ngOnInit(): void {
    this.restart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges changes=', changes);
    this.calculateSizes();

    this.imageTiles.forEach((imageTile) => {
      imageTile.xPercentPosition = this.calculateXPercentPosition(imageTile.index);
      imageTile.yPercentPosition = this.calculateYPercentPosition(imageTile.index);
    });
  }

  restart(): void {
    this.reset();
    this.calculateSizes();
    this.breakImageParts();
    this.imageTiles = this.randomize(this.imageTiles);
  }

  calculateSizes(): void {
    this.boxWidthRatio = 100 / (this.columnCount - 1);
    this.boxHeightRatio = 100 / (this.rowCount - 1);

    this.boxWidth = this.imageWidth / this.columnCount;
    this.boxHeight = this.imageHeight / this.rowCount;
  }

  reset(): void {
    this.imageTiles = [];
    this.indexes = [];
    this.positions = [];

    this.boxWidth = 100 / (this.columnCount - 1);
    this.boxHeight = 100 / (this.rowCount - 1);

    this.totalBoxes = this.columnCount * this.rowCount;
  }

  breakImageParts(): void {
    let index;

    // eslint-disable-next-line no-plusplus
    for (index = 0; index < this.totalBoxes; index++) {
      const xPercentPosition = this.calculateXPercentPosition(index);
      const yPercentPosition = this.calculateYPercentPosition(index);

      this.indexes.push(index);
      this.imageTiles.push({ xPercentPosition, yPercentPosition, index });
    }
  }

  calculateXPercentPosition(index: number): string {
    return `${this.boxWidthRatio * (index % this.columnCount)}%`;
  }

  calculateYPercentPosition(index: number): string {
    return `${this.boxHeightRatio * (Math.floor(index / this.columnCount) % this.rowCount)}%`;
  }

  randomize(imageParts: any[]): any[] {
    const tempImageParts: any = [];
    let ran = 0;

    imageParts.forEach(() => {
      ran = Math.floor(Math.random() * imageParts.length);

      while (imageParts[ran] == null) {
        ran = Math.floor(Math.random() * imageParts.length);
      }

      tempImageParts.push(imageParts[ran]);
      this.positions.push(imageParts[ran].index);
      imageParts[ran] = null;
    });

    return tempImageParts;
  }

  onDragStart(event: any): void {
    event.dataTransfer.setData('data', event.target.id);

    this.draggedId = event.target.id;

    const origin = event.target.id;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const originElement: any = document.getElementById(origin);

    // TODO finish experiments or rm

    // var img = document.createElement('img');
    // img.src = 'http://kryogenix.org/images/hackergotchi-simpler.png';
    // event.dataTransfer.setDragImage(img, 0, 0);

    // const clone = event.target.cloneNode(true);
    // clone.style.position = 'absolute';
    // document.getElementById('outer').appendChild(clone);
    // // document.body.appendChild(clone);
    // // event.dataTransfer.setDragImage(clone, event.clientX + event.layerX, event.clientY + event.layerY);
    // event.dataTransfer.setDragImage(clone, clone.clientWidth / 2, clone.clientHeight / 2);
    // // event.target.style.opacity = 0;
    // // console.log('onDragStart() clone=', clone);

    // TODO finish experiment or rm dragCloneElement impl
    // const clone = event.target.cloneNode(true);
    // clone.style.position = 'absolute';
    // document.getElementById('outer').appendChild(clone);
    // event.dataTransfer.setDragImage(clone, 5000, 5000);

    // this.dragCloneElement = event.target.cloneNode(true);
    // this.dragCloneElement.style.position = 'absolute';
    // this.dragCloneElement.style.opacity = '0.9';
    // document.body.appendChild(this.dragCloneElement);

    // console.log('onDragStart() event=', event);
  }

  onDrop(event: any): void {
    this.draggedId = null;

    const origin = event.dataTransfer.getData('data');
    const destination = event.target.id;

    const originElement = document.getElementById(origin);
    const destinationElement = document.getElementById(destination);

    const originCss = originElement!.style.cssText;
    const destinationCss = event.target.style.cssText;

    destinationElement!.style.cssText = originCss;
    originElement!.style.cssText = destinationCss;
    originElement!.id = destination;
    destinationElement!.id = origin;

    for (let i = 0; i < this.positions.length; i += 1) {
      if (this.positions[i].toString() === originElement!.id) {
        this.positions[i] = Number(destinationElement!.id);
      } else if (this.positions[i].toString() === destinationElement!.id) {
        this.positions[i] = Number(originElement!.id);
      }
    }

    this.steps += 1;

    this.puzzleChange.emit(this.steps);
    this.puzzleComplete.emit(this.isSorted(this.positions));
  }

  isSorted(indexes: any[]): boolean {
    let i = 0;
    for (i = 0; i < indexes.length; i += 1) {
      if (indexes[i] !== i) {
        return false;
      }
    }
    return true;
  }

  onDragOver(event: any): void {
    // TODO finish experiment or rm dragCloneElement impl
    // this.dragCloneElement.style.left = event.pageX - 10 + 'px';
    // this.dragCloneElement.style.top = event.pageY - event.target.clientHeight - 10 + 'px';

    event.preventDefault();
    // event.target.style.opacity = 1;
    // console.log('onDragOver() event=', event);
  }

  onDragLeave(event: any): void {
    event.preventDefault();
    // console.log('onDragLeave() event=', event);
  }

  onDragEnd(): void {
    this.draggedId = null;

    // TODO finish experiment or rm dragCloneElement impl
    // this.dragCloneElement.style.display = 'none';
    // this.dragCloneElement.remove();
    // this.dragCloneElement = null;
    // console.log('onDragEnd() event=', event);
  }
}
