import { Component, OnInit } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { AuthStoreService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
})
export class FullLayoutComponent implements OnInit {
  headerFixed = environment.headerFixed;

  hideHeaderFooter = false;

  authenticatedUser$ = this.authStore.authenticatedUser$.pipe(untilDestroyed(this));

  constructor(private router: Router, private authStore: AuthStoreService) {}

  ngOnInit(): void {
    this.router.events.pipe(filter((event) => event instanceof ActivationStart)).subscribe((event: any) => {
      this.hideHeaderFooter = event.snapshot?.data?.hideHeaderFooter;
    });
  }
}
