import { Component } from '@angular/core';
import { AuthStoreService } from '@motivforce/mx-library-angular';
import { filter, map } from 'rxjs';
import { UserStoreService } from 'src/app/core/store/user-store.service';

@Component({
  selector: 'app-account-summary-widget',
  templateUrl: './account-summary-widget.component.html',
  styleUrls: ['./account-summary-widget.component.scss'],
})
export class AccountSummaryWidgetComponent {
  currentYear = new Date().getFullYear();

  accountSummary$ = this.userStore.accountSummary$;
  currentYearAccountSummary$ = this.userStore.yearAccountSummaries$.pipe(
    filter(Boolean),
    map((yearAccountSummaries) =>
      yearAccountSummaries.filter((yearAccountSummary) => yearAccountSummary.programYear === this.currentYear)
    ),
    map((yearAccountSummaries) => yearAccountSummaries[0])
  );

  constructor(private userStore: UserStoreService, private authStore: AuthStoreService) {}
}
