<div class="container-fluid">
  <div class="row">
    <div class="px-0 col-12 col-lg-9 background-wrapper">
      <img class="w-100" [src]="background" />
    </div>
    <div class="col-12 col-lg-3 login-form-container">
      <div class="login-wrapper w-100 h-100 d-flex align-items-center justify-content-center">
        <form
          [formGroup]="formGroup"
          class="my-5 mt-lg-0 p-4 w-100 d-flex d-sm-none d-lg-flex flex-column"
          (ngSubmit)="onSubmit()"
        >
          <h1 class="title">{{ 'Welcome to TradeUp' | translate }}</h1>
          <label>Sign in</label>
          <div class="pt-2 w-100 form-group">
            <label class="form-label">Email</label>
            <input id="email" type="text" formControlName="email" class="w-100 form-control" />
          </div>
          <div class="pt-4 w-100 form-group">
            <label class="form-label">Password</label>
            <input id="password" type="password" formControlName="password" class="w-100 form-control" />
          </div>
          <button class="mt-5 w-100 btn btn-primary" type="submit">Sign in</button>
          <!-- <button class="mt-4 w-100 btn btn-primary google" type="button" (click)="signInWithGoogle()">
            Login with Google
          </button> -->
          <div class="my-3 w-100">
            <a class="me-3" [routerLink]="'/auth/forgot-password'">Forgotten your password?</a>
          </div>
          <div class="w-100">
            <p class="w-100 mb-2">Don't have an account?</p>
            <button [routerLink]="'/registration'" class="btn w-100 btn-outline-primary">Create an account</button>
          </div>
        </form>

        <form
          [formGroup]="formGroup"
          class="my-5 mt-lg-0 p-4 w-50 d-none d-sm-flex d-lg-none flex-column"
          (ngSubmit)="onSubmit()"
        >
          <h1 class="title">{{ 'Welcome to TradeUp' | translate }}</h1>
          <label>Sign in</label>
          <div class="pt-4 w-100 form-group">
            <label class="form-label">Email</label>
            <input id="email" type="text" formControlName="email" class="w-100 form-control" />
          </div>
          <div class="pt-4 w-100 form-group">
            <label class="form-label">Password</label>
            <input id="password" type="password" formControlName="password" class="w-100 form-control" />
          </div>
          <button class="mt-5 w-100 btn btn-primary" type="submit">Login</button>
          <!-- <button class="mt-4 w-100 btn btn-primary google" type="button" (click)="signInWithGoogle()">
            Login with Google
          </button> -->
          <div class="w-100">
            <p class="w-100 mb-2">Don't have an account?</p>
            <a [routerLink]="'/registration'" class="btn w-100 btn-outline-primary">Create an account</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
