<div class="icon-wrapper">
  <ng-container *ngIf="!isMultiselect">
    <select class="form-control" [formControl]="control">
      <option [ngValue]="null" [attr.hidden]="hidePlaceholderOption ? true : null">{{ placeholder }}</option>
      <option class="option" *ngFor="let option of dataProvider" [ngValue]="option[valueField]">
        {{ (labelFunction ? labelFunction(option) : labelField ? option[labelField] : option) | translate }}
      </option>
    </select>

    <fa-icon [icon]="['far', 'chevron-down']" size="sm"></fa-icon>
  </ng-container>

  <ng-container *ngIf="isMultiselect">
    <select class="form-control" [formControl]="control" multiple size="10">
      <option [ngValue]="null" [attr.hidden]="hidePlaceholderOption ? true : null">{{ placeholder }}</option>
      <option class="option" *ngFor="let option of dataProvider" [ngValue]="option[valueField]">
        {{ (labelFunction ? labelFunction(option) : labelField ? option[labelField] : option) | translate }}
      </option>
    </select>
  </ng-container>
</div>
