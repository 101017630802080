import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AccountSummary,
  AuthStoreService,
  LearnModulesStoreService,
  MxLoggerService,
  ReferenceData,
  SearchResult,
  DialogNgbService,
  RedeemedReward,
} from '@motivforce/mx-library-angular';
import { IsLoadingService } from '@service-work/is-loading';
import { BehaviorSubject } from 'rxjs';
import { pairwise } from 'rxjs/operators';

import { UserRestService } from '../api/rest/user-rest.service';
import { Booking } from '../model/booking';
import { BookingSearch } from '../model/booking-search';
import { CommunicationsPreference } from '../model/core/communications-preference';
import { Currency } from '../model/core/currency';
import { Notification } from '../model/core/notification';
import { Profile } from '../model/core/profile';
import { RedemptionSearch } from '../model/core/redemption-search';
import { Sale } from '../model/core/sale';
import { SetPassword } from '../model/core/set-password';
import { Transaction } from '../model/core/transaction';
import { TransactionSearch } from '../model/core/transaction-search';
import { LeapPerformanceSummary } from '../model/leap-performance-summary';
import { LenovoProfile } from '../model/lenovo/lenovo-profile';
import { UserProfile } from '../model/user-profile';

@Injectable({
  providedIn: 'root',
})
export class UserStoreService {
  private readonly _accountSummary = new BehaviorSubject<AccountSummary | null>(null);
  readonly accountSummary$ = this._accountSummary.asObservable();

  private readonly _yearAccountSummaries = new BehaviorSubject<AccountSummary[] | null>(null);
  readonly yearAccountSummaries$ = this._yearAccountSummaries.asObservable();

  private readonly _leapPerformanceSummary = new BehaviorSubject<LeapPerformanceSummary | null>(null);
  readonly leapPerformanceSummary$ = this._leapPerformanceSummary.asObservable();

  private readonly _profile = new BehaviorSubject<Profile | null>(null);
  readonly profile$ = this._profile.asObservable();

  private readonly _lenovoProfile = new BehaviorSubject<LenovoProfile | null>(null);
  readonly lenovoProfile$ = this._lenovoProfile.asObservable();

  private readonly _currentTransactionResults = new BehaviorSubject<SearchResult<Transaction> | null>(null);
  readonly currentTransactionResults$ = this._currentTransactionResults.asObservable();

  private readonly _currentPromotionResults = new BehaviorSubject<SearchResult<Transaction> | null>(null);
  readonly currentPromotionResults$ = this._currentPromotionResults.asObservable();

  private readonly _currentRedeemedRewardResults = new BehaviorSubject<SearchResult<RedeemedReward> | null>(null);
  readonly currentRedeemedRewardResults$ = this._currentRedeemedRewardResults.asObservable();

  private readonly _currentBookingsResults = new BehaviorSubject<SearchResult<any> | null>(null);
  readonly currentBookingsResults$ = this._currentBookingsResults.asObservable();

  private readonly _transactionTypes = new BehaviorSubject<ReferenceData[] | null>(null);
  readonly transactionTypes$ = this._transactionTypes.asObservable();

  private readonly _typeaheadVenues = new BehaviorSubject<any[] | null>(null);
  readonly typeaheadVenues$ = this._typeaheadVenues.asObservable();

  private readonly _paymentIntent = new BehaviorSubject<any | null>(null);
  readonly paymentIntent$ = this._paymentIntent.asObservable();

  private readonly _communicationPreferences = new BehaviorSubject<CommunicationsPreference | null>(null);
  readonly communicationPreferences$ = this._communicationPreferences.asObservable();

  private readonly _notifications = new BehaviorSubject<Notification[] | null>(null);
  readonly notifications$ = this._notifications.asObservable();

  private readonly _userCurrency = new BehaviorSubject<Currency | null>(null);
  readonly userCurrency$ = this._userCurrency.asObservable();

  private readonly _userProfile = new BehaviorSubject<UserProfile | null>(null);
  readonly userProfile$ = this._userProfile.asObservable();

  constructor(
    private userRest: UserRestService,
    private isLoadingService: IsLoadingService,
    private dialog: DialogNgbService,
    private learnModuleStore: LearnModulesStoreService,
    private mxLogger: MxLoggerService,
    private authStore: AuthStoreService,
    private router: Router
  ) {
    this.learnModuleStore.currentLearnModule$
      .pipe(pairwise())
      .subscribe(([previousLearnModule, currentLearnModule]) => {
        if (
          previousLearnModule &&
          previousLearnModule.id === currentLearnModule!.id &&
          !previousLearnModule.pointsAwarded &&
          currentLearnModule!.pointsAwarded
        ) {
          this.mxLogger.info('UserStoreService', 'Current Learn Module points awarded. Get user account summary.');
          this.getAccountSummary();
        }
      });
  }

  get accountSummary(): AccountSummary | null {
    return this._accountSummary.getValue();
  }

  get yearAccountSummaries(): AccountSummary[] | null {
    return this._yearAccountSummaries.getValue();
  }

  get profile(): Profile | null {
    return this._profile.getValue();
  }

  get lenovoProfile(): LenovoProfile | null {
    return this._lenovoProfile.getValue();
  }

  get currentTransactionResults(): SearchResult<Transaction> | null {
    return this._currentTransactionResults.getValue();
  }

  get currentPromotionResults(): SearchResult<Transaction> | null {
    return this._currentPromotionResults.getValue();
  }

  get currentRedeemedRewardResults(): SearchResult<RedeemedReward> | null {
    return this._currentRedeemedRewardResults.getValue();
  }

  get currentBookingsResults(): SearchResult<Sale> | null {
    return this._currentBookingsResults.getValue();
  }

  get transactionTypes(): ReferenceData[] | null {
    return this._transactionTypes.getValue();
  }

  get typeaheadVenues(): any[] | null {
    return this._typeaheadVenues.value;
  }

  get communicationPreferences(): CommunicationsPreference | null {
    return this._communicationPreferences.getValue();
  }

  get userCurrency(): Currency | null {
    return this._userCurrency.getValue();
  }

  get userProfile(): UserProfile | null {
    return this._userProfile.getValue();
  }

  getAccountSummary(): void {
    this.isLoadingService.add(
      this.userRest.getAccountSummary().subscribe((accountSummary: AccountSummary) => {
        this._accountSummary.next(accountSummary);
      })
    );
  }

  getYearAccountSummaries(): void {
    this.isLoadingService.add(
      this.userRest.getYearAccountSummaries().subscribe((yearAccountSummaries: AccountSummary[]) => {
        this._yearAccountSummaries.next(yearAccountSummaries);
      })
    );
  }

  getLeapPerformanceSummary(): void {
    this.isLoadingService.add(
      this.userRest.getLeapPerformanceSummary().subscribe((summary) => {
        this._leapPerformanceSummary.next(summary);
      })
    );
  }

  getProfile(): void {
    this.isLoadingService.add(
      this.userRest.getProfile().subscribe((profile: Profile) => {
        this._profile.next(profile);
      })
    );
  }

  getLenovoProfile(): void {
    this.isLoadingService.add(
      this.userRest.getLenovoProfile().subscribe((lenovoProfile: LenovoProfile) => {
        this._lenovoProfile.next(lenovoProfile);
      })
    );
  }

  updateRole(profileRoleName: string): void {
    this.isLoadingService.add(
      this.userRest.updateRole(profileRoleName).subscribe(() => {
        this.dialog.openNotification(['You have updated your role successfully.'], '');
      })
    );
  }

  searchTransactions(searchCriteria: TransactionSearch): void {
    this.isLoadingService.add(
      this.userRest.searchTransactions(searchCriteria).subscribe((searchResult: SearchResult<Transaction>) => {
        this._currentTransactionResults.next(searchResult);
      })
    );
  }

  getTransactionTypes(): void {
    if (this.transactionTypes) {
      return;
    }

    this.isLoadingService.add(
      this.userRest.getTransactionTypes().subscribe((transactionTypes: ReferenceData[]) => {
        this._transactionTypes.next(transactionTypes);
      })
    );
  }

  searchPromotions(searchCriteria: TransactionSearch): void {
    this.isLoadingService.add(
      this.userRest.searchPromotions(searchCriteria).subscribe((searchResult: SearchResult<Transaction>) => {
        this._currentPromotionResults.next(searchResult);
      })
    );
  }

  searchRedeemedRewards(searchCriteria: RedemptionSearch): void {
    this.isLoadingService.add(
      this.userRest.searchRedeemedRewards(searchCriteria).subscribe((searchResult: SearchResult<RedeemedReward>) => {
        this._currentRedeemedRewardResults.next(searchResult);
      })
    );
  }

  searchBookings(searchCriteria: BookingSearch): void {
    this.isLoadingService.add(
      this.userRest.searchBookings(searchCriteria).subscribe((searchResult: SearchResult<Booking>) => {
        this._currentBookingsResults.next(searchResult);
      })
    );
  }

  getCommunicationPreferences(): void {
    this.isLoadingService.add(
      this.userRest.getCommunicationPreferences().subscribe((communicationPreferences: CommunicationsPreference) => {
        this._communicationPreferences.next(communicationPreferences);
      })
    );
  }

  updateCommunicationPreferences(communicationPreferences: CommunicationsPreference): void {
    this.isLoadingService.add(
      this.userRest.updateCommunicationPreferences(communicationPreferences).subscribe(() => {
        this.dialog.openNotification(['You have updated your communication preferences.'], '');
      })
    );
  }

  acceptTermsConditions(): void {
    this.isLoadingService.add(
      this.userRest.acceptTermsConditions().subscribe(() => {
        this.authStore.getUserSettings();
      })
    );
  }

  typeaheadUserSearchVenue(venueSearch: any): void {
    this.isLoadingService.add(
      this.userRest.searchAvailableVenues(venueSearch).subscribe((venues: any[]) => this._typeaheadVenues.next(venues))
    );
  }

  updateVenue(venueId: any): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.userRest.updateVenue(venueId).subscribe(
          () => resolve(true),
          () => resolve(false)
        )
      );
    });
  }

  setPassword(password: SetPassword): void {
    this.isLoadingService.add(
      this.userRest.setPassword(password).subscribe(() => {
        this.dialog.openNotification(['You have updated your password successfully.'], '');
        this.router.navigate(['/auth/login']);
      })
    );
  }

  createCheckoutSession(form: any): void {
    this.isLoadingService.add(
      this.userRest.createCheckoutSession(form).subscribe((paymentIntent) => {
        this._paymentIntent.next(paymentIntent);
      })
    );
  }

  getNotifications(): void {
    this.isLoadingService.add(
      this.userRest.getNotifications().subscribe((notifications) => {
        this._notifications.next(notifications);
      })
    );
  }

  setNotificationDisplayed(id: number): void {
    this.isLoadingService.add(this.userRest.setNotificationDisplayed(id));
  }

  getUserCurrency(): void {
    if (this.userCurrency) {
      return;
    }

    this.isLoadingService.add(
      this.userRest.getUserCurrency().subscribe((currency) => {
        this._userCurrency.next(currency);
      })
    );
  }

  getUserProfile(): void {
    this.isLoadingService.add(
      this.userRest.getUserProfile().subscribe((profile) => {
        this._userProfile.next(profile);
      })
    );
  }

  updateUserProfile(profile: UserProfile): void {
    this.isLoadingService.add(
      this.userRest.updateUserProfile(profile).subscribe(() => {
        this.dialog.openNotification(['Details was successfully updated.'], '');
      })
    );
  }
}
