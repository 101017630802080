import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-open-padding-wrapper',
  templateUrl: './modal-open-padding-wrapper.component.html',
  styleUrls: ['./modal-open-padding-wrapper.component.scss'],
})
export class ModalOpenPaddingWrapperComponent implements OnInit {
  scrollBarWidth: number;

  ngOnInit(): void {
    this.resetScrollbarWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.resetScrollbarWidth();
  }

  resetScrollbarWidth(): void {
    const scrollDiv = document.createElement('div');
    scrollDiv.className = 'modal-scrollbar-measure';
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);

    this.scrollBarWidth = scrollbarWidth;
  }
}
