import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, TranslateResolver } from '@motivforce/mx-library-angular';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full',
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then((m) => m.LandingModule),
    canActivate: [],
  },
  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
    canActivate: [],
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then((m) => m.RegistrationModule),
    canActivate: [],
  },
  {
    path: 'thank-you',
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [],
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'my-claims',
    loadChildren: () => import('./my-claims/my-claims.module').then((m) => m.MyClaimsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'eligible-products',
    loadChildren: () => import('./eligible-products/eligible-products.module').then((m) => m.EligibleProductsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'my-details',
    loadChildren: () => import('./my-details/my-details.module').then((m) => m.MyDetailsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'terms-and-conditions',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Terms and conditions') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [],
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
    canActivate: [],
  },
  {
    path: 'rewards-catalogue',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Rewards catalogue') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./product-catalogue/product-catalogue.module').then((m) => m.ProductCatalogueModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'account-summary',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Account summary') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./account-summary/account-summary.module').then((m) => m.AccountSummaryModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'account-summary-statement',
    loadChildren: () => import('./account-summary/account-summary.module').then((m) => m.AccountSummaryModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'promotions',
    loadChildren: () => import('./promotion/promotion.module').then((m) => m.PromotionModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'program-overview',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Program overview') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('./program-overview/program-overview.module').then((m) => m.ProgramOverviewModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'take-a-course',
    loadChildren: () => import('./learn-modules/learn-modules.module').then((m) => m.LearnModulesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'my-leap',
    loadChildren: () => import('./my-leap/my-leap.module').then((m) => m.MyLeapModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
