import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesSeconds',
})
export class MinutesSecondsPipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (value != null) {
      if (typeof value !== 'number') {
        throw new Error('MinutesSecondsPipe value type should be number');
      } else {
        const minutesValue = Math.round((value as number) / 60);
        const secondsValue = (value as number) % 60;

        const formattedString = `${minutesValue}:${secondsValue < 10 ? '0' : ''}${secondsValue}`;
        return formattedString;
      }
    }
    return null;
  }
}
