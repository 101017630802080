<div class="d-flex flex-column stats">
  <h4 class="offcanvas-body text-uppercase">{{ 'Shopping cart' | translate }}</h4>

  <div class="offcanvas-body d-flex align-items-center">
    <div class="w-50">
      <strong>{{ 'Product' | translate }}</strong>
    </div>
    <div class="w-25">
      <strong>{{ 'Qty' | translate }}</strong>
    </div>
    <div class="mx-1">
      <strong>{{ 'Price' | translate }}</strong>
    </div>
    <div class="flex-fill d-flex justify-content-end">
      <div class="px-1"></div>
    </div>
  </div>

  <div>
    <hr />
  </div>

  <ng-container *ngFor="let item of cart?.products">
    <div class="offcanvas-body d-flex">
      <div class="w-50">
        {{ item.name }}
      </div>
      <div class="w-25">
        {{ item.quantity }}
      </div>
      <div class="mx-1">
        {{ item.points | number }}
      </div>
      <div class="flex-fill d-flex justify-content-end">
        <div>
          <button class="p-0 btn close-button" (click)="removeItem(item)">
            <fa-icon [icon]="['fal', 'xmark']" size="lg"></fa-icon>
          </button>
        </div>
      </div>
    </div>

    <div>
      <hr />
    </div>
  </ng-container>

  <div class="offcanvas-body d-flex align-items-center justify-content-between">
    <div>
      <strong>{{ cart?.products?.length }} {{ 'items' | translate }}</strong>
    </div>

    <div class="me-2">
      <strong>{{ 'Subtotal' | translate }}: {{ cartTotal | number }}</strong>
    </div>
  </div>

  <div class="offcanvas-body" *ngIf="showWishlistButton">
    <a class="mt-2 w-100 btn btn-primary" [routerLink]="'/rewards-catalogue/cart'">{{ 'View cart' | translate }}</a>
    <a class="mt-4 w-100 btn btn-outline-primary" [routerLink]="'/rewards-catalogue/wishlist'">{{
      'View wishlist' | translate
    }}</a>
    <a class="mt-3 p-0 btn btn-link" type="button" (click)="clearCart()">
      {{ 'Clear cart' | translate }}
    </a>
  </div>
</div>
