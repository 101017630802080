<ng-container *ngIf="mode === 'prompt'"
  ><h3 class="pt-3 text-uppercase header">Do you want to take the challenge?</h3>
  <h1 class="text-uppercase header">Question of the week</h1>
  <h3 class="text-uppercase header">You'll get extra points rewarded</h3>
</ng-container>

<div
  class="w-100 h-100 d-flex justify-content-center align-items-center"
  [ngClass]="{ 'pt-2': mode === 'prompt', 'pb-3': mode === 'prompt' }"
>
  <div
    class="d-flex flex-column justify-content-around align-items-center qow-content"
    [ngClass]="{ 'qow-content-question': mode === 'question' }"
  >
    <ng-container *ngIf="mode === 'prompt'">
      <div class="w-75 p-4 mt-4">
        <h6 class="m-3 text-uppercase text-center">
          <strong>Do you want to take the question of the week challenge?</strong>
        </h6>
      </div>

      <div class="w-100 d-flex justify-content-center align-items-center">
        <button class="btn btn-primary" type="button" (click)="startQow()">YES</button>
        <button class="btn btn-primary ms-4" type="button" (click)="activeModal.close('no')">NO</button>
      </div>

      <div class="w-100 p-3 text-center" *ngIf="context === 'homePage'">
        <a [routerLink]="'/promotions/qow'" (click)="activeModal.close('no')">Previous Results</a>
      </div>
    </ng-container>
    <ng-container *ngIf="mode === 'question'">
      <div class="w-100 p-3 d-flex justify-content-end align-items-center">
        <fa-icon [icon]="['far', 'clock']" size="xs"></fa-icon>
        <span class="ms-1 elapsed-seconds">{{ quiz.timeElapsed }}</span>
      </div>

      <div class="w-100 d-flex justify-content-around align-items-center">
        <lib-quiz-question
          [quizName]="quiz.promotionName"
          [question]="quiz.questions[0]"
          [showButton]="false"
          [showQuestionNumber]="false"
        ></lib-quiz-question>
      </div>

      <div class="w-100 pb-5 d-flex justify-content-center align-items-center">
        <button
          class="btn btn-primary"
          type="button"
          (click)="submitQow()"
          [disabled]="!quiz.questions[0].userAnswerId"
        >
          Submit
        </button>
        <button class="btn btn-primary ms-4" type="button" (click)="cancelQow()">Cancel</button>
      </div>
    </ng-container>
    <ng-container *ngIf="mode === 'result'">
      <div class="w-75 p-2 mt-4">
        <h5 class="m-2 text-uppercase text-center">
          <strong>{{ resultTitle }}</strong>
        </h5>
      </div>

      <div class="w-100 p-5 d-flex justify-content-around align-items-center text-center result-message">
        {{ resultMessage }}
      </div>

      <div class="w-100 pb-5 d-flex justify-content-around align-items-center">
        <button class="btn btn-primary" type="button" (click)="activeModal.close()">Close</button>
      </div>
    </ng-container>
  </div>
</div>
